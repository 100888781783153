.json-view {
	display: block;
	color: #4d4d4d;
	text-align: left;
	--json-property: #009033;
	--json-index: #676dff;
	--json-number: #676dff;
	--json-string: #b2762e;
	--json-boolean: #dc155e;
	--json-null: #dc155e;
}
.json-view .json-view--property {
	color: var(--json-property);
}
.json-view .json-view--index {
	color: var(--json-index);
}
.json-view .json-view--number {
	color: var(--json-number);
}
.json-view .json-view--string {
	color: var(--json-string);
}
.json-view .json-view--boolean {
	color: var(--json-boolean);
}
.json-view .json-view--null {
	color: var(--json-null);
}

.json-view .jv-indent {
	padding-left: 1em;
}
.json-view .jv-chevron {
	display: inline-block;
	vertical-align: -20%;
	cursor: pointer;
	opacity: 0.4;
	width: 1em;
	height: 1em;
}
:is(.json-view .jv-chevron:hover, .json-view .jv-size:hover + .jv-chevron) {
	opacity: 0.8;
}
.json-view .jv-size {
	cursor: pointer;
	opacity: 0.4;
	font-size: 0.875em;
	font-style: italic;
	margin-left: 0.5em;
	vertical-align: -5%;
	line-height: 1;
}

.json-view :is(.json-view--copy, .json-view--edit),
.json-view .json-view--link svg {
	display: none;
	width: 1em;
	height: 1em;
	margin-left: 0.25em;
	cursor: pointer;
}

.json-view .json-view--input {
	width: 120px;
	margin-left: 0.25em;
	border-radius: 4px;
	border: 1px solid currentColor;
	padding: 0px 4px;
	font-size: 87.5%;
	line-height: 1.25;
	background: transparent;
}
.json-view .json-view--deleting {
	outline: 1px solid #da0000;
	background-color: #da000011;
	text-decoration-line: line-through;
}

:is(.json-view:hover, .json-view--pair:hover) > :is(.json-view--copy, .json-view--edit),
:is(.json-view:hover, .json-view--pair:hover) > .json-view--link svg {
	display: inline-block;
}

.json-view .jv-button {
	background: transparent;
	outline: none;
	border: none;
	cursor: pointer;
	color: inherit;
}
.json-view .cursor-pointer {
	cursor: pointer;
}

.json-view svg {
	vertical-align: -10%;
}
.jv-size-chevron ~ svg {
	vertical-align: -16%;
}

/* Themes */
.json-view_a11y {
	color: #545454;
	--json-property: #aa5d00;
	--json-index: #007299;
	--json-number: #007299;
	--json-string: #008000;
	--json-boolean: #d91e18;
	--json-null: #d91e18;
}
.json-view_github {
	color: #005cc5;
	--json-property: #005cc5;
	--json-index: #005cc5;
	--json-number: #005cc5;
	--json-string: #032f62;
	--json-boolean: #005cc5;
	--json-null: #005cc5;
}
.json-view_vscode {
	color: #005cc5;
	--json-property: #0451a5;
	--json-index: #0000ff;
	--json-number: #0000ff;
	--json-string: #a31515;
	--json-boolean: #0000ff;
	--json-null: #0000ff;
}
.json-view_atom {
	color: #383a42;
	--json-property: #e45649;
	--json-index: #986801;
	--json-number: #986801;
	--json-string: #50a14f;
	--json-boolean: #0184bc;
	--json-null: #0184bc;
}
.json-view_winter-is-coming {
	color: #0431fa;
	--json-property: #3a9685;
	--json-index: #ae408b;
	--json-number: #ae408b;
	--json-string: #8123a9;
	--json-boolean: #0184bc;
	--json-null: #0184bc;
}
